import {useEffect, useState} from 'react'
import {getActiveLastNDailyTargetsCached} from 'src/util/db'
import {Panel} from './Panel'
import {HStack} from './Stack'
import {LoaderWithText} from 'src/Loader'
import {DailyTarget} from './DailyTarget'
import {getFormattedDateForDailyTarget} from 'src/utils'
import {isMobile} from 'src/utils'

const DailyTargetTile = ({showFreeOnly, count = 6}) => {
  const [targets, setTargets] = useState()
  useEffect(() => {
    getActiveLastNDailyTargetsCached(count).then((targets) => {
      // create a new array to avoid mutating the original array
      const targetsCopy = targets.slice()
      targetsCopy.forEach((target, index) => {
        target.formattedDate = getFormattedDateForDailyTarget(target.startDate)
      })

      if (showFreeOnly || isMobile()) {
        targetsCopy = targetsCopy.slice(0, 2)
      }

      let tomorrow = +new Date(targetsCopy[0].startDate.seconds) + 24 * 60 * 60

      targetsCopy.reverse().push({
        id: 'dummy',
        image: '/targets/0.png?v=2',
        formattedDate: 'Tomorrow',
        date: tomorrow,
      })

      setTargets(targetsCopy)
    })
  }, [])

  useEffect(() => {
    if (isMobile()) {
      return
    }

    const todayElement = document.querySelector('.target-today')
    if (todayElement) {
      todayElement.scrollIntoView({inline: 'center', block: 'nearest'})
    }
  }, [targets])

  return (
    <Panel padding="0" topFocus={showFreeOnly}>
      <div
        style={{padding: '2rem'}}
        className="home-daily-target-panel no-scrollbar"
      >
        {targets ? (
          <HStack
            gap="2rem"
            align="flex-start"
            justify={showFreeOnly && !isMobile() ? 'center' : 'flex-start'}
          >
            {targets.map((target, index) => (
              <div
                key={target.id}
                style={{
                  paddingLeft:
                    index == 0 && !isMobile() && !showFreeOnly ? '10%' : 0,
                  paddingRight:
                    target.id == 'dummy' && !showFreeOnly ? '40%' : 0,
                }}
              >
                <DailyTarget target={target} lazy={false} />
              </div>
            ))}
          </HStack>
        ) : (
          <LoaderWithText>Fetching Daily targets...</LoaderWithText>
        )}
      </div>

      <div className={`${!showFreeOnly && 'daily-targets-shadowed'}`}></div>
    </Panel>
  )
}

export {DailyTargetTile}
