import React, {useState} from 'react'
import Link from 'next/link'
import {
  battles,
  getBattleStatus,
  BattleStatuses,
} from '@cssbattle/shared/battles'
import {BattleTile} from 'src/BattleTile'
import {Button} from 'src/Button'
import {saveProfile} from 'src/util/db'
import {sendEvent} from 'src/analytics'
import {useUser} from 'src/hooks/useUser'
import {CreateBattleModal} from 'src/CreateBattleModal'
import {getCustomBattlesByUser} from 'src/util/db'
import {getRedirectLink} from 'src/utils'
import {useWhenMounted} from 'src/hooks/useWhenMounted'
import {HStack, VStack} from '@components/Stack'
import {Text} from '@components/Text'
import {DailyTargetTile} from '@components/DailyTargetTile'
import {Icon} from 'src/Icon'
import {Panel} from '@components/Panel'
import {Separator} from 'src/Common'
import {ActivityStream} from 'src/ActivityStream'
import {isMobile} from 'src/utils'

const HomePage = () => {
  const user = useUser()
  const [isCreateBattleModalOpen, setIsCreateBattleModalOpen] = useState(false)
  // const [customBattles, setCustomBattles] = useState([])

  const isMounted = useWhenMounted()

  // useEffect(() => {
  //   if (!user?.uid) return
  //   getCustomBattlesByUser(user.uid).then(setCustomBattles)
  // }, [user?.uid])

  let currentBattle = battles.find((battle) => {
    return getBattleStatus(battle) === BattleStatuses.ONGOING
  })

  // If no ongoing was found, find last finished one
  if (!currentBattle) {
    currentBattle = battles[battles.length - 1]
  }

  function hostBattleHandler() {
    setIsCreateBattleModalOpen(true)
  }

  const [isTldrValid, setIsTldrValid] = useState(false)
  // useEffect(() => {
  //   const timezoneOffset = new Date().getTimezoneOffset()

  //   if (
  //     (timezoneOffset <= 600 && timezoneOffset >= 240) || //us,canada
  //     timezoneOffset === -780 //new zealand
  //   ) {
  //     setIsTldrValid(true)
  //   } else {
  //     setIsTldrValid(false)
  //   }
  // }, [])

  return (
    <>
      <div style={{maxWidth: '100%'}}>
        <VStack gap="3rem" align="stretch">
          {user && isMounted ? null : (
            <>
              <HStack gap="2rem" responsive>
                <img
                  src="/images/welcome_small.webp"
                  width={isMobile() ? '100%' : '300px'}
                  style={{
                    borderRadius: '16px',
                    aspectRatio: '3/2',
                    imageRendering: 'pixelated',
                  }}
                />

                <VStack gap="1.5rem" align="flex-start">
                  <VStack gap="0.5rem" align="flex-start">
                    <Text
                      size="4"
                      weight="600"
                      mono={false}
                      tag="h1"
                      appearance="primary"
                    >
                      Welcome to CSSBattle
                    </Text>

                    <div style={{maxWidth: '80ch'}}>
                      <Text
                        size="2"
                        weight="500"
                        mono={false}
                        tag="p"
                        appearance="secondary"
                      >
                        The funnest multiplayer game with 300K+ web designers &
                        developers. Replicate the target images using CSS - the
                        shorter your code, the higher your score! Happy coding!
                      </Text>
                    </div>
                  </VStack>

                  <HStack>
                    <Link href="/login">
                      <a className="button button--primary">
                        Sign In / Sign Up
                      </a>
                    </Link>
                    {/* <Link href="/daily">
                      <a className="button">
                        <Icon
                          name="daily-targets"
                          color="var(--clr-text-light)"
                        />
                        Daily targets
                      </a>
                    </Link>
                    <Link href="/battles">
                      <a className="button">
                        <Icon name="battle" color="var(--clr-text-light)" />
                        Multiplayer battles
                      </a>
                    </Link>
                    <Link href="/learn">
                      <a className="button">
                        <Icon name="learn" color="var(--clr-text-light)" />
                        Learn CSS
                      </a>
                    </Link> */}
                  </HStack>
                </VStack>
              </HStack>

              <Separator />
            </>
          )}

          <Section
            icon="daily-targets"
            title="Daily targets"
            subTitle=" A new target everyday for you to unwind. No leaderboards, no
            competition"
            moreContent={
              <Link href="/daily">
                <a className="button">View all daily targets</a>
              </Link>
            }
          >
            <DailyTargetTile />
          </Section>

          <ActivityStream />

          <div id="sponsor">
            <VStack gap="1rem" align="stretch">
              <HStack justify="space-between">
                <Text
                  size="3-5"
                  weight="600"
                  mono={false}
                  tag="h2"
                  appearance="primary"
                >
                  Our sponsor
                </Text>

                <Link href="/advertise">
                  <a className="button">Advertise with us</a>
                </Link>
              </HStack>

              <Panel padding="1rem" onlyBorder>
                {isTldrValid !== undefined ? (
                  <a
                    href={
                      isTldrValid
                        ? getRedirectLink(
                            'https://tldr.tech/signup?utm_source=css-battle&utm_campaign=css-battle-trial-campaign&utm_medium=display-ads',
                            99
                          )
                        : 'https://webmaker.app/?utm_source=cssbattle'
                    }
                    target="_blank"
                    rel="noopener"
                    style={{
                      display: 'block',
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <img
                      style={{
                        width: '100%',
                        borderRadius: '8px',
                        aspectRatio: '400/25',
                      }}
                      src={
                        isTldrValid
                          ? '/images/tldr-banner.jpeg'
                          : '/images/webmaker-home-banner.webp'
                      }
                    />
                  </a>
                ) : null}
              </Panel>
            </VStack>
          </div>

          <Section
            icon="battle"
            title="Latest battle"
            subTitle="Compete with players around the world in this nail-biting
CSS Battle"
            moreContent={
              <HStack gap="0.75rem">
                <Link href="/battles">
                  <a className="button">View all battles</a>
                </Link>
                <Button onClick={() => hostBattleHandler()}>
                  Host a private battle
                </Button>
              </HStack>
            }
          >
            <BattleTile battle={currentBattle} />
          </Section>
        </VStack>
      </div>

      <CreateBattleModal
        isOpen={isCreateBattleModalOpen}
        onRequestClose={() => setIsCreateBattleModalOpen(false)}
      />
    </>
  )
}

const Section = ({icon, title, subTitle, moreContent, children}) => {
  return (
    <VStack gap="1.5rem" align="stretch">
      <HStack justify="space-between" responsive>
        <HStack align={isMobile() ? 'flex-start' : 'center'}>
          <div
            style={{
              height: '3.5rem',
              width: '3.5rem',
              borderRadius: '50%',
              background: 'var(--clr-border-1)',
              textAlign: 'center',
              lineHeight: '3.5rem',
              flexShrink: 0,
            }}
          >
            <Icon
              name={icon}
              marginRight="0"
              size="24"
              color="var(--clr-brand)"
            />
          </div>

          <VStack gap="0.1rem" align="flex-start">
            <Text
              size="3-5"
              weight="600"
              mono={false}
              tag="h2"
              appearance="primary"
            >
              {title}
            </Text>

            <div style={{maxWidth: '72ch'}}>
              <Text
                size={1}
                weight="500"
                mono={false}
                tag="p"
                letterSpacing="0.3px"
                appearance="secondary"
                lineHeight="1.5"
              >
                {subTitle}
              </Text>
            </div>
          </VStack>
        </HStack>

        <div>{moreContent}</div>
      </HStack>
      {children}
    </VStack>
  )
}
function SubscribeBox() {
  const user = useUser()
  const [isSaving, setIsSaving] = useState()

  function notifyButtonHandler() {
    setIsSaving(true)
    sendEvent({name: 'emailNotificationUpdated', label: 1})
    saveProfile(user.uid, {
      'settings.emailNotifications': true,
    }).then(() => {
      setIsSaving(false)
    })
  }

  function shouldShowNotifyButton() {
    return (
      (!user.settings || !user.settings.emailNotifications) &&
      isSaving !== false
    )
  }

  return (
    <>
      {shouldShowNotifyButton() && (
        <div className="subscribe-box">
          <h3>Subscribe for updates</h3>

          <Button primary onClick={notifyButtonHandler} isLoading={isSaving}>
            Get Notified via Email about new Battles
          </Button>
          <p className="para">We won't spam you ever :)</p>
          {isSaving === false ? (
            <p className="para">Great! We'll notify you about new Battles.</p>
          ) : null}
        </div>
      )}
    </>
  )
}
export default HomePage
